import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OutboundLink } from "gatsby-plugin-google-gtag";
const ProductHighlightDrPepperZero = () => {
  return (
    <>
      <Row>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B000R9COOY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B000R9COOY&linkCode=as2&tag=exclusiveg01c-20&linkId=0f228f22de37bbae534a812ed3992aa2`}
          >
            <StaticImage src="../images/dr_pepper_original_12_pack_cans.jpg" alt="dr_pepper_original_12_pack_cans" placeholder="blurred" width={300} height={300} />
            <br />
            .5 Liter bottles, 6 Pack
          </OutboundLink>
        </Col>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B08XXDQB1D/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08XXDQB1D&linkId=610005ccfe5cb7917be90545c6eab24d`}
          >
            <StaticImage src="../images/drpepper-zero-2-liters.jpg" alt="dr pepper 2 liter bottle" placeholder="blurred" width={300} height={300} />
            <br /> 2 Liter Bottle
          </OutboundLink>
        </Col>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B08XXGB5NK/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08XXGB5NK&linkId=8c034d96c664608808c0a500b16ea2e0`}
          >
            <StaticImage src="../images/dr_pepper_zero_12_pack_cans.jpg" alt="Dr Pepper Zero 12 Pack Cans" placeholder="blurred" width={300} height={300} />
            <br />
            12 fl oz cans, 12 Pack
          </OutboundLink>
        </Col>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B01FHTGFL6/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01FHTGFL6&linkId=b58b3b67b9e0a1357319dc060282e1de`}
          >
            <StaticImage src="../images/dr_pepper_original_8_pack_bottle.jpg" alt="dr_pepper_original_8_pack_bottle" placeholder="blurred" width={300} height={300} />
            <br /> 20 fl oz bottle
          </OutboundLink>
        </Col>
      </Row>
    </>
  );
};
export default ProductHighlightDrPepperZero;
