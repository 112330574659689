import React from "react";
import { Link } from "gatsby";

const ProductLinkListHorizonal = ({ title }) => {
  let titleHtml;
  if (title) titleHtml = <h2>{title}</h2>;
  return (
    <div className="my-5 ml-auto">
      {undefined !== title && titleHtml}
      <p className="d-block">Learn more about other dr pepper products produced by the Keurig Dr Pepper company.</p>
      <div className="ml-auto flex-wrap d-md-inline-flex">
        <ul id="product-link-horizontal-list" className="flex-wrap d-md-inline-flex">
          <li>
            <Link to="/drpepper/">Dr Pepper</Link>
          </li>
          <li>
            <Link to="/drpepper-diet/">Dr Pepper Diet</Link>
          </li>
          <li>
            <Link to="/drpepper-zero/">Dr Pepper Zero Sugar</Link>
          </li>
          <li>
            <Link to="/drpepper-cream-soda/">Dr Pepper Cream Soda</Link>
          </li>
          <li>
            <Link to="/drpepper-cream-soda-zero/">Dr Pepper Cream Soda Zero Sugar</Link>
          </li>
          <li>
            <Link to="/drpepper-cherry/">Dr Pepper Cherry</Link>
          </li>
          <li>
            <Link to="/drpepper-cherry-zero/">Dr Pepper Cherry Zero</Link>
          </li>
          <li>
            <Link to="/drpepper-cherry-vanilla/">Dr Pepper Cherry Vanilla</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default ProductLinkListHorizonal;
