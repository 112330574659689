import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlightDrPepperZero from "../components/ProductHighlightDrPepperZero";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Content = () => {
  const title = "Dr Pepper Zero";
  const description =
    "What's more, exactly when you figured wonderful flavor couldn't get any more amazing er. This zero-sugar enamors your tastebuds with the notable mix of 23 flavors. It's the delectable twofold take your tastebuds merit.";

  return (
    <Layout>
      <Seo title={title} description={description} />
      <Container>
        <Row>
          <Col>
            <h1>{title}</h1>
            <p>{description}</p>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h2>Nutrition Facts</h2>
            <p>Size 12 fl oz</p>
            <table className="nutrition-table">
              <thead>
                <tr>
                  <th>Per Serving</th>
                  <th>% DAILY VALUE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Calories: </b>0
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Total Fat: </b>0 g
                  </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    <b>Sodium: </b>60 mg
                  </td>
                  <td>3%</td>
                </tr>
                <tr>
                  <td>
                    <b>Total Carbohydrates: </b>0 g
                  </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    <b>Protein: </b>0 g
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4 mb-3">*Based on a 2000 calorie diet</p>
          </Col>

          <Col sm={8}>
            <h2 className="mt-4 mb-3">{title} Ingredients</h2>
            <ul>
              <li>Carbonated Water</li>
              <li>Caramel Color</li>
              <li>Phosphoric Acid</li>
              <li>Aspartame</li>
              <li>Sodium Benzoate(preservative)</li>
              <li>Caffeine</li>
              <li>Acesulfame Potassium</li>
              <li>Natural And Artificial Flavors</li>
              <li>Sodium Phosphate</li>
            </ul>
            <h2>Does Dr Pepper Zero Sugar Have Caffeine?</h2>
            <p>Most definitely. Caffeine is one of the ingredients of Dr Pepper Zero Sugar, as you can see from the ingredients list above.</p>

            <h2>How many calories does Dr Pepper Zero have?</h2>
            <p>Dr Pepper Zero Sugar has 0 Calories.</p>

            <h2>How much sugar does dr pepper zero have?</h2>
            <p>
              Dr Pepper Zero Sugar as the name implies does not have any sugar in it. Instead, use an artificial sweetener as a combination of Aspartame and Acesulfame Potassium to get its rich sweet
              flavor without the added sugar. You will be able to see the mention of Aspartame and Acesulfame Potassium in the nutrition facts table above and on product labels when your are
              purchasing this delicious product at the store.
            </p>

            <h2>Carbohydrates in Dr Pepper Zero</h2>
            <p>Dr Pepper Zero contains 0g total carbs and 0g net carbs.</p>
          </Col>

          <Col sm={12} className="mb-5">
            <h2 className="mt-4 mb-3">Available Sizes</h2>
            <ProductHighlightDrPepperZero />
          </Col>
        </Row>

        <Row>
          <Col>
            <ProductLinkListHorizonal title="Products" />
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>References</h2>
            <ol class="reference-listing">
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/products/drpepper-zero`}>
                  Dr Pepper Zero Sugar Product Page
                </OutboundLink>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
